<ng-container *ngIf="showRegistrationPopup">
  <div
  class="relative z-[9999] font-montserrat"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="fixed inset-0 bg-gray-500/75 transition-opacity"
    aria-hidden="true"
  ></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 layout-overlay"
    >
      <div
        class="relative transform overflow-hidden bg-white text-left shadow-xl transition-all my-3 md:m-8 max-w-[450px] md:max-w-[1256px] register-popup"
      >
        <div>
          <div class="flex justify-end absolute right-0 p-2">
            <svg
              (click)="onClosePopup()"
              class="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 32 32"
            >
              <path
                fill="#a4a4a4"
                d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"
              />
            </svg>
          </div>
          <div class="grid md:grid-cols-2 lg:grid-cols-3">
            <div class="col-span-2 lg:col-span-1 md:col-span-2">
              <div
                class="bg-light-blue p-4 pt-6 lg:p-6 h-full flex flex-wrap items-center"
              >
                <div>
                  <img
                    src="../../assets/images/logo-icon-80.png"
                    alt
                    class="max-w-14"
                  />
                  <h3
                    class="text-lg md:text-xl lg:text-2xl font-extrabold text-black my-4 md:my-6 lg:my-[42px]"
                    id="modal-title"
                  >
                    GET STARTED WITH CRICKTEAMS
                  </h3>
                  <p class="text-sm text-black mb-4 md:mb-[24px] font-medium">
                    Create your FREE account & start explore you cricket
                    companion
                  </p>
                  <ul>
                    <li
                      class="flex items-center gap-2 lg:gap-[15px] mb-2 md:mb-3.5 lg:mb-[26px] text-black text-xs md:text-sm"
                    >
                      <span>
                        <svg
                          width="16"
                          height="12"
                          viewBox="0 0 16 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.54972 9.15L14.0247 0.675C14.2247 0.475 14.4581 0.375 14.7247 0.375C14.9914 0.375 15.2247 0.475 15.4247 0.675C15.6247 0.875 15.7247 1.1125 15.7247 1.3875C15.7247 1.6625 15.6247 1.9 15.4247 2.1L6.24972 11.3C6.04972 11.5 5.81639 11.6 5.54972 11.6C5.28305 11.6 5.04972 11.5 4.84972 11.3L0.549719 7C0.349719 6.8 0.253885 6.5625 0.262219 6.2875C0.270552 6.0125 0.374719 5.775 0.574719 5.575C0.774719 5.375 1.01222 5.275 1.28722 5.275C1.56222 5.275 1.79972 5.375 1.99972 5.575L5.54972 9.15Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Comprehensive Team Management for crick-life balance
                    </li>
                    <li
                      class="flex items-center gap-2 lg:gap-[15px] mb-2 md:mb-3.5 lg:mb-[26px] text-black text-xs md:text-sm"
                    >
                      <span>
                        <svg
                          width="16"
                          height="12"
                          viewBox="0 0 16 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.54972 9.15L14.0247 0.675C14.2247 0.475 14.4581 0.375 14.7247 0.375C14.9914 0.375 15.2247 0.475 15.4247 0.675C15.6247 0.875 15.7247 1.1125 15.7247 1.3875C15.7247 1.6625 15.6247 1.9 15.4247 2.1L6.24972 11.3C6.04972 11.5 5.81639 11.6 5.54972 11.6C5.28305 11.6 5.04972 11.5 4.84972 11.3L0.549719 7C0.349719 6.8 0.253885 6.5625 0.262219 6.2875C0.270552 6.0125 0.374719 5.775 0.574719 5.575C0.774719 5.375 1.01222 5.275 1.28722 5.275C1.56222 5.275 1.79972 5.375 1.99972 5.575L5.54972 9.15Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Attendance Made Simple, with automated reminders.
                    </li>
                    <li
                      class="flex items-center gap-2 lg:gap-[15px] mb-2 md:mb-3.5 lg:mb-[26px] text-black text-xs md:text-sm"
                    >
                      <span>
                        <svg
                          width="16"
                          height="12"
                          viewBox="0 0 16 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.54972 9.15L14.0247 0.675C14.2247 0.475 14.4581 0.375 14.7247 0.375C14.9914 0.375 15.2247 0.475 15.4247 0.675C15.6247 0.875 15.7247 1.1125 15.7247 1.3875C15.7247 1.6625 15.6247 1.9 15.4247 2.1L6.24972 11.3C6.04972 11.5 5.81639 11.6 5.54972 11.6C5.28305 11.6 5.04972 11.5 4.84972 11.3L0.549719 7C0.349719 6.8 0.253885 6.5625 0.262219 6.2875C0.270552 6.0125 0.374719 5.775 0.574719 5.575C0.774719 5.375 1.01222 5.275 1.28722 5.275C1.56222 5.275 1.79972 5.375 1.99972 5.575L5.54972 9.15Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Instant Annoucements.
                    </li>
                    <li
                      class="flex items-center gap-2 lg:gap-[15px] mb-2 md:mb-3.5 lg:mb-[26px] text-black text-xs md:text-sm"
                    >
                      <span>
                        <svg
                          width="16"
                          height="12"
                          viewBox="0 0 16 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.54972 9.15L14.0247 0.675C14.2247 0.475 14.4581 0.375 14.7247 0.375C14.9914 0.375 15.2247 0.475 15.4247 0.675C15.6247 0.875 15.7247 1.1125 15.7247 1.3875C15.7247 1.6625 15.6247 1.9 15.4247 2.1L6.24972 11.3C6.04972 11.5 5.81639 11.6 5.54972 11.6C5.28305 11.6 5.04972 11.5 4.84972 11.3L0.549719 7C0.349719 6.8 0.253885 6.5625 0.262219 6.2875C0.270552 6.0125 0.374719 5.775 0.574719 5.575C0.774719 5.375 1.01222 5.275 1.28722 5.275C1.56222 5.275 1.79972 5.375 1.99972 5.575L5.54972 9.15Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Player-Centric Cricket History
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="col-span-2 signup-bg py-4 md:py-6 lg:py-[73px] pl-4 md:pl-6 pr-4 md:pr-6 lg:pr-[60px]"
            >
              <div>
                <h3
                  class="text-black text-xl md:text-2xl lg:text-[32px] mb-5 title-line"
                  id="modal-title"
                >
                  Register Your Club
                </h3>
                <p class="text-xs md:text-sm text-light-grey md:leading-6 mt-3">
                  This platform is exclusively designed for
                  <span class="font-semibold">cricket clubs</span>, providing
                  them with a dedicated space to connect, collaborate, and
                  manage their cricketing activities.
                </p>

                <form
                  class="mt-4 md:mt-6 lg:mt-9"
                  [formGroup]="clubRegistrationForm"
                  (ngSubmit)="onSubmit()"
                  #form="ngForm"
                >
                  <div class="mb-5 lg:mb-7 error-message">
                    <cricteams-dy-input
                      [labelClass]="labelClass"
                      [inputClass]="inputClass"
                      [form]="form"
                      [field]="clubName"
                      [group]="dyForm"
                    ></cricteams-dy-input>
                  </div>

                  <div class="grid lg:grid-cols-12 lg:gap-6">
                    <div class="lg:col-span-6 mb-5 lg:mb-7 error-message">
                      <cricteams-dy-input
                        [labelClass]="labelClass"
                        [inputClass]="inputClass"
                        [form]="form"
                        [field]="email"
                        [group]="dyForm"
                      ></cricteams-dy-input>
                    </div>
                    <div class="lg:col-span-6 mb-5 lg:mb-7 error-message">
                      <cricteams-dy-input
                        [labelClass]="labelClass"
                        [inputClass]="inputClass"
                        [form]="form"
                        [field]="websiteUrl"
                        [group]="dyForm"
                      ></cricteams-dy-input>
                    </div>
                  </div>

                  <div class="grid lg:grid-cols-12 lg:gap-6">
                    <div class="lg:col-span-6 error-message mb-5">
                      <cricteams-dy-input
                        [labelClass]="labelClass"
                        [inputClass]="inputClass"
                        [form]="form"
                        [field]="city"
                        [group]="dyForm"
                      ></cricteams-dy-input>
                    </div>

                    <div class="lg:col-span-6 error-message mb-5">
                      <cricteams-dy-select
                        [labelClass]="labelClass"
                        [selectClass]="inputClass"
                        [form]="form"
                        [field]="state"
                        [group]="dyForm"
                      ></cricteams-dy-select>
                    </div>
                  </div>

                  <div
                    *ngIf="errorMessage"
                    class="pt-5 border-t border-gray-100 dark:border-slate-800"
                  >
                    <div class="space-y-3">
                      <div
                        class="relative px-4 py-2 rounded-md font-medium bg-red-600/10 border border-red-600/10 text-red-600 block"
                      >
                        {{ errorMessage }}
                      </div>
                    </div>
                  </div>

                  <div class="text-right mt-7">
                    <cricteams-dy-button
                      type="submit"
                      text="SUBMIT"
                      [disabled]="dyForm.invalid"
                      [conditionalClass]="{
                        'bg-light-blue': dyForm.invalid,
                        'bg-dark-blue cursor-pointer': dyForm.valid
                      }"
                      [class]="btnClass"
                    ></cricteams-dy-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>
<cricteams-mail-confirmation #mailConfirmation></cricteams-mail-confirmation>
