@if (!isTournametDataAvailable) {
<div class="bg-white rounded-md border-[1px] border-light-grey p-4 lg:p-6 font-montserrat main-wrapper">
  <div class="flex items-center justify-between mb-6">
    <h2 class="font-semibold text-black text-base">Tournaments</h2>
    <select id="state" name="state" autocomplete="state-name" (change)="filterTournaments($event)"
      class="bg-white rounded border-[1px] border-light-blue w-auto px-3 h-[34px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none">
      <option value="" selected disabled>Year</option>
      <option value="all" >All</option>
      <option *ngFor="let year of TournamentStartYears">{{ year }}</option>
    </select>
  </div>
  <div class="search-input">
    <input type="text" [(ngModel)]="tournamentSearchKeyword"
      class="border-[1px] border-light-blue text-xs h-[34px] text-black p-2 w-full rounded pl-10 outline-none"
      placeholder="Search...">
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M7.33333 13.0002C10.2789 13.0002 12.6667 10.6123 12.6667 7.66683C12.6667 4.72131 10.2789 2.3335 7.33333 2.3335C4.38781 2.3335 2 4.72131 2 7.66683C2 10.6123 4.38781 13.0002 7.33333 13.0002Z"
          stroke="#161C2D" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.9995 14.3335L11.1328 11.4668" stroke="#161C2D" stroke-width="1.33333" stroke-linecap="round"
          stroke-linejoin="round" />
      </g>
    </svg>
  </div>

  <div class="custom-scroll">
    @if (tournamentsList && tournamentsList.length > 0) {
      @if ((tournamentsList | search: tournamentSearchKeyword: 'name').length) {
        <div
          class="overflow-auto w-full gap-6 mt-6 flex items-center cursor-pointer justify-between bg-light-slate p-3 border-[1px] border-light-grey rounded"
          *ngFor="let item of tournamentsList | search: tournamentSearchKeyword: 'name'; let i = index;" (click)="handleTournamentSelection(item)">
          <div class="bg-white min-w-8 min-h-8 flex items-center justify-center">
            <span class="text-dark-blue text-xs font-semibold">{{ commonService.generateBadge(item.name) }}</span>
          </div>
          <div class="gap-4 flex items-center justify-between w-full">
            <h3 class="text-base font-semibold text-black xl:min-w-[145px] overflow-hidden text-ellipsis whitespace-nowrap">{{
              item.name
              }}</h3>
            <div class="w-fit 2xl:w-[30%]">
              <span class="text-xs text-black">{{ item.startDate | date: 'MM/dd/yyyy' }}</span>
            </div>
          </div>
        </div>
      } @else {
        <div
          class="p-10 border-[1px] border-dashed border-light-blue max-w-[1118px] flex item-center justify-center rounded mt-[34px]">
          <p class="text-xs text-black">No tournaments found!</p>
        </div>
      }
    }
    @else {
    <div
      class="p-10 border-[1px] border-dashed border-light-blue max-w-[1118px] flex item-center justify-center rounded mt-[34px]">
      <p class="text-xs text-black">Tournaments are empty</p>
    </div>
    }
  </div>
</div>
}@else {
<div class="bg-white rounded border-[1px] border-light-grey p-4 lg:p-6 h-full">
  <div class="flex justify-between flex-col h-full">
    <div>
      <div class="flex items-center justify-between border-b-[1px] border-light-blue pb-2.5">
        <h5 class=" text-black text-base">Detail View</h5>
        <svg class="size-4 cursor-pointer" width="28" height="28" viewBox="0 0 28 28" fill="none" (click)="closeDetailView()"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.46683 22.1666L5.8335 20.5333L12.3668 14L5.8335 7.46665L7.46683 5.83331L14.0002 12.3666L20.5335 5.83331L22.1668 7.46665L15.6335 14L22.1668 20.5333L20.5335 22.1666L14.0002 15.6333L7.46683 22.1666Z"
            fill="#1D1B20"></path>
        </svg>
      </div>
      <div class="flex items-center justify-between mt-4 mb-2.5">
        <h2 class="font-semibold text-black text-base">{{ selectedTournament.name }}</h2>
        <button (click)="handleEditTournament(editTournamentModal)">
          <svg class="size-4" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 19.75H6.425L16.2 9.975L14.775 8.55L5 18.325V19.75ZM3 21.75V17.5L16.2 4.325C16.4 4.14167 16.6208 4 16.8625 3.9C17.1042 3.8 17.3583 3.75 17.625 3.75C17.8917 3.75 18.15 3.8 18.4 3.9C18.65 4 18.8667 4.15 19.05 4.35L20.425 5.75C20.625 5.93333 20.7708 6.15 20.8625 6.4C20.9542 6.65 21 6.9 21 7.15C21 7.41667 20.9542 7.67083 20.8625 7.9125C20.7708 8.15417 20.625 8.375 20.425 8.575L7.25 21.75H3ZM15.475 9.275L14.775 8.55L16.2 9.975L15.475 9.275Z"
              fill="black" fill-opacity="0.5" />
          </svg>
        </button>
      </div>
      <div class="mb-2 flex items-center gap-4 text-xs font-montserrat text-light-grey">
        <label for="">League Name :</label>
        <span class="text-black text-xs">{{ selectedTournament.league.name }}</span>
      </div>
      <div class="mb-2 flex items-center gap-4 text-xs font-montserrat text-light-grey">
        <label for="">Start Date: </label>
        <span class="text-black text-xs">{{ selectedTournament.startDate | date: 'MM/dd/yyyy' }}</span>
      </div>
      <div class="mb-6 flex items-center gap-4 text-sm font-montserrat text-light-grey">
        <label for="">End Date: </label>
        <span class="text-black text-xs">{{ selectedTournament.endDate | date: 'MM/dd/yyyy' }}</span>
      </div>
      <div class="flex items-center justify-between py-2.5 border-b-[1px] border-t-[1px] border-light-blue">
        <h2 class="font-semibold text-black text-base">Matches</h2>
        <select id="state" name="state" autocomplete="state-name" (change)="filterTournaments($event)"
          class="bg-white rounded border-[1px] border-light-blue w-auto px-3 h-[34px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none">
          <option value="" disabled selected>Year</option>
          <option value="all">All</option>
          <option *ngFor="let year of MatchStartYears" >{{ year }}</option>
        </select>
      </div>
      @if (matchList && matchList.length > 0) {
        <div class="overflow-auto hide-scroll h-[calc(100vh_-_580px)]">
          <div
            class="overflow-auto w-full gap-6 mt-6 flex items-center cursor-pointer justify-between bg-light-slate p-3 border-[1px] border-light-grey rounded"
            *ngFor="let item of matchList;">
            <div class="gap-4 flex items-center justify-between w-full">
              <h3 class="text-base font-semibold text-black xl:min-w-[145px] overflow-hidden text-ellipsis whitespace-nowrap">{{
                item.opponentName
                }}</h3>
              <div class="w-fit 2xl:w-[30%]">
                <span class="text-xs text-black">{{ item.startDateTime | date: 'MM/dd/yyyy' }}</span>
              </div>
            </div>
          </div>
        </div>
      }@else {
      <div
        class="p-6 border-[1px] border-dashed border-light-blue max-w-[1118px] flex item-center justify-center rounded mt-6 ">
        <p class="text-xs text-black">No match found for selected tournament</p>
      </div>
      }
    </div>
    <div class="text-center">
      <button type="button" [routerLink]="'/' + constants.ADD_MATCHES"
        class="text-white text-xs bg-dark-blue font-semibold py-1 px-3 h-[34px] md:h-[40px] w-fit hover:bg-darkBlue transition duration-700 ease-in-out ">+
        ADD MATCHES</button>
    </div>
  </div>
</div>
}

<ng-template #editTournamentModal>
  <cricteams-add-tournament [isModal]="true" [isEdit]="true" [selectedTournament]="selectedTournament"
    (onCloseModal)="handleClose($event)"></cricteams-add-tournament>
</ng-template>
