import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AuthService } from '@auth0/auth0-angular';
import { ApiResponse } from '../models/api-response';
import { firstValueFrom } from 'rxjs';
import { League } from '../models/league/league-info.model';

@Injectable({
  providedIn: 'root',
})
export class LeagueService {
  constructor(private restService: RestService, private auth: AuthService) {}

  public async getLeagues(): Promise<ApiResponse<League[]> | unknown> {
    try {
      const apiUrl = '/leagues';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<League[]>>(
        `${apiUrl}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async getMyLeagues(clubId: string): Promise<Array<League> | null> {
    try {
      const apiUrl = '/clubs/leagues';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(this.auth.getAccessTokenSilently());
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Array<League>>>(
        `${apiUrl}/${clubId}`
      );
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      return null;
    }
  }
}
