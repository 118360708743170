<nav id="sidebar" class="sidebar-wrapper sidebar-dark bg-light-blue font-montserrat ">
  <div class="sidebar-content">
    <div class="logo sidebar-brand pt-8 pl-[55px]">
      <a routerLink="/"><img src="assets/images/sidebar-logo.png" height="12" alt="" class="max-w-[140px]"></a>
    </div>

    <ul class=" pt-8" simplebar style="height: calc(100% - 70px);">
      <li class="sidebar-dropdown hover:bg-light-sky transition duration-500 ease-in-out new-sidebar-li p-2 ml-0 pl-8"
        routerLinkActive="active ms-0" [routerLinkActiveOptions]="{ exact: true }">
        <a [routerLink]="constants.DASHBOARD" class="flex items-center text-sm">
          <div class="min-w-8 min-h-8 flex items-center justify-center">
            <img src="assets/images/app/sidebar/dashboard-sidebar-unselected.svg" alt="Tournaments" class="w-[15px] h-[15px] me-2" *ngIf="!isActive(constants.DASHBOARD)">
              <img src="assets/images/app/sidebar/dashboard-sidebar-selected.svg" alt="Tournaments" class="w-[15px] h-[15px] me-2" *ngIf="isActive(constants.DASHBOARD)">
          </div>
          Dashboard</a>
      </li>
      <li class="sidebar-dropdown hover:bg-light-sky transition duration-500 ease-in-out new-sidebar-li p-2 ml-0 pl-8" routerLinkActive="active ms-0">
        <a [routerLink]="constants.TOURNAMENTS" class="flex items-center text-sm">
          <div class="min-w-8 min-h-8 flex items-center justify-center">
            <img src="assets/images/app/sidebar/tournament-sidebar-unselected.svg" alt="Tournaments" class="w-[20px] h-[20px] me-2" *ngIf="!isActive(constants.TOURNAMENTS)">
            <img src="assets/images/app/sidebar/tournament-sidebar-selected.svg" alt="Tournaments" class="w-[20px] h-[20px] me-2" *ngIf="isActive(constants.TOURNAMENTS)">
          </div>
          Tournaments</a>
      </li>
      <li class="sidebar-dropdown hover:bg-light-sky transition duration-500 ease-in-out new-sidebar-li p-2 ml-0 pl-8" routerLinkActive="active ms-0">
        <a [routerLink]="constants.MATCHES" class="flex items-center text-sm">
          <div class="min-w-8 min-h-8 flex items-center justify-center">
            <img src="assets/images/app/sidebar/match-sidebar-unselected.svg" alt="Matches" class="w-[20px] h-[20px] me-2" *ngIf="!isActive(constants.MATCHES)">
            <img src="assets/images/app/sidebar/match-sidebar-selected.svg" alt="Matches" class="w-[20px] h-[20px] me-2" *ngIf="isActive(constants.MATCHES)">    
          </div>
          Matches</a>
      </li>

      <li class="sidebar-dropdown hover:bg-light-sky transition duration-500 ease-in-out new-sidebar-li p-2 ml-0 pl-8" routerLinkActive="active ms-0">
        <a [routerLink]="constants.PRACTICES" class="flex items-center text-sm">
          <div class="min-w-8 min-h-8 flex items-center justify-center">
            <img src="assets/images/app/sidebar/practice-sidebar-unselected.svg" alt="Practices" class="w-[25px] h-[25px] me-2" *ngIf="!isActive(constants.PRACTICES)">
            <img src="assets/images/app/sidebar/practice-sidebar-selected.svg" alt="Practices" class="w-[25px] h-[25px] me-2" *ngIf="isActive(constants.PRACTICES)">
          </div>
          Practices</a>
      </li>

      <li *ngIf="isManagerOrCaptain" class="sidebar-dropdown hover:bg-light-sky transition duration-500 ease-in-out new-sidebar-li p-2 ml-0 pl-8" routerLinkActive="active ms-0">
        <a [routerLink]="constants.PLAYERS" class="flex items-center text-sm">
          <div class="min-w-8 min-h-8 flex items-center justify-center">
            <img src="assets/images/app/sidebar/player-sidebar-unselected.svg" alt="Players" class="w-[20px] h-[20px] me-2" *ngIf="!isActive(constants.PLAYERS)">
            <img src="assets/images/app/sidebar/player-sidebar-selected.svg" alt="Players" class="w-[20px] h-[20px] me-2" *ngIf="isActive(constants.PLAYERS)">
          </div>
          Players</a>
      </li>

      <!-- <li class="sidebar-dropdown hover:bg-light-sky transition duration-500 ease-in-out new-sidebar-li p-2 ml-0 pl-8" routerLinkActive="active ms-0" [routerLinkActiveOptions]="{ exact: true }">
        <a [routerLink]="constants.NOTICEBOARD + '/' + constants.NB_INDEX"
          (click)="toggleSubmenu(constants.NOTICEBOARD + '/' + constants.NB_INDEX)">
          <i class="uil uil-chart-line w-[20px] h-[20px] me-2"></i>Notice Board
        </a>
        <div class="sidebar-submenu"
          [ngClass]="{'block ms-0': manuOpen === ('/' + constants.NOTICEBOARD + '/' + constants.NB_INDEX) || manuOpen === ('/' + constants.NOTICEBOARD + '/' + constants.GALLERY)}">
          <ul>
            <li [ngClass]="manuOpen === (constants.NOTICEBOARD + '/' + constants.NB_INDEX) ? 'active ms-0' : ''">
              <a [routerLink]="constants.NOTICEBOARD + '/' + constants.NB_INDEX" routerLinkActive="active ms-0"
                [routerLinkActiveOptions]="{ exact: true }">
                Feed
              </a>
            </li>
            <li [ngClass]="manuOpen === (constants.NOTICEBOARD + '/' + constants.GALLERY) ? 'active ms-0' : ''">
              <a [routerLink]="constants.NOTICEBOARD + '/' + constants.GALLERY" routerLinkActive="active ms-0"
                [routerLinkActiveOptions]="{ exact: true }">
                Gallery
              </a>
            </li>
          </ul>
        </div>
      </li> -->
      <li *ngIf="isManagerOrCaptain" class="sidebar-dropdown hover:bg-light-sky transition duration-500 ease-in-out new-sidebar-li p-2 ml-0 pl-8" routerLinkActive="active ms-0" [routerLinkActiveOptions]="{ exact: true }">
        <a [routerLink]="constants.SETTINGS" class="flex items-center text-sm">
          <div class="min-w-8 min-h-8 flex items-center justify-center">
            <img src="assets/images/app/sidebar/settings-sidebar-unselected.svg" alt="Settings" class="w-[20px] h-[20px] me-2" *ngIf="!isActive(constants.SETTINGS)">
            <img src="assets/images/app/sidebar/settings-sidebar-selected.svg" alt="Settings" class="w-[20px] h-[20px] me-2" *ngIf="isActive(constants.SETTINGS)">
          </div>
          Settings</a>
      </li>
      <!-- <li class="relative lg:m-8 m-6 px-8 py-10 rounded-lg bg-gradient-to-b to-transparent from-slate-800 text-center">
        <span class="relative z-10">
            <span class="text-xl font-bold h5 text-[#161c2d]">Upgrade to Pro</span>

            <span class="text-slate-400 text-[#161c2d] mt-3 mb-5 block">Get one month free and subscribe to pro</span>

            <a href="" target="_blank" class="py-2 px-5 bg-darkBlue inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center hover:bg-gray-500 border-gray-500/10 hover:border-gray-500 text-white rounded-md">Subscribe</a>
        </span>
      </li> -->
    </ul>
    <!-- sidebar-menu  -->
  </div>
</nav>
