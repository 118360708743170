import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { DyButtonComponent } from '../../../components/form-fields/dy-button/dy-button.component';

@Component({
  selector: 'cricteams-mail-confirmation',
  standalone: true,
  imports: [CommonModule, DyButtonComponent],
  templateUrl: './mail-confirmation.component.html',
  styleUrl: './mail-confirmation.component.scss',
})
export class MailConfirmationComponent {
  public btnClass = "md:w-[164px] h-[36px] md:h-[42px] text-white bg-dark-blue p-2 text-sm cursor-pointer whitespace-nowrap leading-4 hover:bg-darkBlue transition duration-700 ease-in-out";
  public backBtnClass =
    'w-[164px] h-[42px] text-white bg-red-400 p-3 cursor-pointer whitespace-nowrap leading-4 hover:bg-red-500 transition duration-700 ease-in-out';
  public message =
    'Please verify your email to complete the registration process.';
  public showConfirmationPopup = false;
  public emailId = "";

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent): void {
    const modalElement = event.target as HTMLElement;
    modalElement.classList;
    if (modalElement.classList.contains('layout-overlay')) {
      this.onClosePopup();
    }
  }

  goToInbox() {
    const domain = this.emailId.split('@')[1]?.toLowerCase();
    switch (domain) {
      case 'gmail.com':
        window.open('https://mail.google.com/mail/u/0/#inbox', '_blank');
        break;
      case 'yahoo.com':
        window.open('https://mail.yahoo.com/', '_blank');
        break;
      case 'outlook.com':
        window.open('https://outlook.live.com/mail/inbox', '_blank');
        break;
      default:
        this.message = 'Please open your mailbox manually to check your email.';
        break;
    }
  }

  public showPopup(emailId: string) {
    this.emailId = emailId;
    this.showConfirmationPopup = true;
  }

  public onClosePopup() {
    this.showConfirmationPopup = false;
  }
}
