import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAuth0 } from '@auth0/auth0-angular';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([BrowserAnimationsModule]),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideAuth0({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0ApiAudience
      },
      cacheLocation: 'localstorage',
    })
  ]
};
