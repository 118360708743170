import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import * as feather from 'feather-icons';
import { filter } from 'rxjs';
import SimpleBar from 'simplebar';
import { routeUrls } from '../../constants/constants';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'cricteams-sidebar',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements AfterViewInit, OnInit {
  constants = routeUrls;
  isManagerOrCaptain!: boolean;

  constructor(private el: ElementRef, private router: Router, private userService:UserService) {
    this.userService.isManagerOrCaptain.subscribe((isManagerOrCaptain) => {
      this.isManagerOrCaptain = isManagerOrCaptain;
    });
  }

  ngAfterViewInit() {
    const element = this.el.nativeElement.querySelector('[simplebar]');
    if (element) {
      new SimpleBar(element);
    }
    feather.replace();
  }

  activeManu = '';
  manuOpen = '';

  ngOnInit(): void {
    this.activeManu = this.router.url;
    window.scrollTo(0, 0);
    this.manuOpen = this.activeManu;
    this.openMenu();
    this.userService.isUserManagerOrCaptain();
  }

  isActive(route: string): boolean {
    return this.activeManu.includes(route);
  }

  openMenu() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.activeManu = this.router.url;
        window.scrollTo(0, 0);
        this.manuOpen = this.activeManu;
      });
  }

  toggleSubmenu(item: string): void {
    if (this.manuOpen === item) {
      this.manuOpen = '';
    } else {
      this.manuOpen = item;
    }
  }
}
