import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ApiResponse } from '../models/api-response';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {

    constructor(private restService: RestService) { }

    public async validateInvitationAsync(id:string): Promise<boolean>{
        try {
            const apiUrl = '/invitation/validate';
            const restInstance = this.restService.getRestInstance();
            const response = await restInstance.get<ApiResponse<boolean>>(`${apiUrl}?id=${id}`);
            console.log(response.data);
            return response.data.isSuccess?response.data.data??false:false;
        } catch (error) {
            console.error('Error submitting contact form:', error);
            return false;
        }
    }

    public async registerClub(data: Record<string, string | number | boolean | unknown>): Promise<unknown> {
      try {
          const apiUrl = '/clubs';
          const restInstance = this.restService.getRestInstance();
          const response = await restInstance.post<ApiResponse<unknown>>(apiUrl, {
              clubName: data['clubName'],
              contactEmail: data['email'],
              city: data['city'],
              state: data['state'],
              websiteUrl: data['websiteUrl'],
          });
          return response.data;
      } catch (error) {
          return error;
      }

    }
}
