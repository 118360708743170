import { Component } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { routeUrls } from '../../../constants/constants';

@Component({
  selector: 'cricteams-app-footer-one',
  standalone: true,
  imports: [RouterLink, RouterModule],
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent {
  date = 0;
  constants = routeUrls;

  ngOnInit() {

    this.date = new Date().getFullYear()
  }
}
