<div class="top-header font-montserrat">
  <div class="header-bar flex justify-between p-4">
      <div class="flex items-center space-x-1">
          <!-- Logo -->
          <!-- <a routerLink="#" class="xl:hidden block me-2">
              <img src="assets/images/logo-icon-32.png" class="md:hidden block" alt="">
              <span class="md:block hidden">
                  <img src="assets/images/logo-dark.png" class="inline-block dark:hidden" alt="">
                  <img src="assets/images/logo-light.png" class="hidden dark:inline-block" alt="">
              </span>
          </a> -->
          <!-- Logo -->

          <!-- Searchbar -->
          <!-- <div class="ps-1.5">
              <div class="form-icon relative sm:block hidden">
                  <i class="uil uil-search absolute top-1/2 -translate-y-1/2 start-3"></i>
                  <input type="text" class="form-input w-56 ps-9 py-2 px-3 h-8 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-3xl outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 bg-white" name="s" id="searchItem" placeholder="Search...">
              </div>
          </div> -->
          <!-- Searchbar -->

          <!-- show or close sidebar -->
          <a id="close-sidebar" class="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-50 dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700 border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-white rounded-full" routerLink="#" (click)="emitToggleClassEvent()">
              <i data-feather="menu" class="h-4 w-4"></i>
          </a>
          <!-- show or close sidebar -->

          <div>
            <div class="relative">
              <button type="button" class="cursor-pointer w-full grid-cols-1 bg-white sm:py-1.5 pl-1 sm:pl-3 pr-2 text-left text-black font-semibold text-xs sm:text-sm" id="menu-button" aria-expanded="false" aria-haspopup="true" (click)="toggleClubDropdown()">
                <span class="col-start-1 row-start-1 flex items-center gap-1 sm:gap-3 pr-1 sm:pr-6">
                  <img src="../../../assets/images/club.png" alt="" class="size-8 shrink-0 rounded-full">
                  <span class="block truncate">{{clubName}}</span>
                  <svg *ngIf="isClubDropdownOpen && displayClubDropdown" class="col-start-1 row-start-1 self-center justify-self-end text-gray-500 size-3 sm:size-4" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 7.14379L3.9875 12.6563C3.8 12.8438 3.57813 12.9344 3.32188 12.9282C3.06563 12.9219 2.84375 12.825 2.65625 12.6375C2.46875 12.45 2.375 12.2282 2.375 11.9719C2.375 11.7157 2.46875 11.4938 2.65625 11.3063L8.43125 5.55004C8.58125 5.40004 8.75 5.28754 8.9375 5.21254C9.125 5.13754 9.3125 5.10004 9.5 5.10004C9.6875 5.10004 9.875 5.13754 10.0625 5.21254C10.25 5.28754 10.4188 5.40004 10.5688 5.55004L16.3438 11.325C16.5313 11.5125 16.6219 11.7313 16.6156 11.9813C16.6094 12.2313 16.5125 12.45 16.325 12.6375C16.1375 12.825 15.9156 12.9188 15.6594 12.9188C15.4031 12.9188 15.1813 12.825 14.9938 12.6375L9.5 7.14379Z" fill="#999999"/>
                  </svg>
                  <svg *ngIf="!isClubDropdownOpen && displayClubDropdown" class="col-start-1 row-start-1 self-center justify-self-end text-gray-500 size-3 sm:size-4" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 10.8562L15.0125 5.34371C15.2 5.15621 15.4219 5.06559 15.6781 5.07184C15.9344 5.07809 16.1562 5.17496 16.3438 5.36246C16.5313 5.54996 16.625 5.77184 16.625 6.02809C16.625 6.28434 16.5313 6.50621 16.3438 6.69371L10.5688 12.45C10.4188 12.6 10.25 12.7125 10.0625 12.7875C9.875 12.8625 9.6875 12.9 9.5 12.9C9.3125 12.9 9.125 12.8625 8.9375 12.7875C8.75 12.7125 8.58125 12.6 8.43125 12.45L2.65625 6.67496C2.46875 6.48746 2.37812 6.26871 2.38437 6.01871C2.39062 5.76871 2.4875 5.54996 2.675 5.36246C2.8625 5.17496 3.08437 5.08121 3.34062 5.08121C3.59687 5.08121 3.81875 5.17496 4.00625 5.36246L9.5 10.8562Z" fill="#999999"/>
                    </svg>
                </span>
              </button>
              <ul class="w-[200px] sm:w-[330px] absolute z-10 mt-1 rounded-md bg-white p-3 sm:p-5 text-sm sm:text-base shadow-lg ring-1 ring-black/5 focus:outline-none " tabindex="-1" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1" *ngIf="isClubDropdownOpen && displayClubDropdown">
                <li class="relative">
                  <input [(ngModel)]="clubSearchKeyword" type="text" placeholder="Search..." class="text-xs md:text-base text-black py-[4px] pr-3 w-full rounded-full pl-7 sm:pl-10 border border-light-blue outline-none">
                  <svg class="absolute inset-1/2 left-2 sm:left-3.5 translate-y-[-50%]" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.5">
                          <path d="M7.33333 13.0002C10.2789 13.0002 12.6667 10.6123 12.6667 7.66683C12.6667 4.72131 10.2789 2.3335 7.33333 2.3335C4.38781 2.3335 2 4.72131 2 7.66683C2 10.6123 4.38781 13.0002 7.33333 13.0002Z" stroke="#161C2D" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M13.9995 14.3335L11.1328 11.4668" stroke="#161C2D" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                        </svg>
                </li>
                @if (clubsList && clubsList.length > 0) {
                  @if ((clubsList | search: clubSearchKeyword: 'name' : 'city').length) {
                    <li (click)="onClubSelect(club)" *ngFor="let club of clubsList | search: clubSearchKeyword: 'name' : 'city'; let last = last"  class="cursor-pointer select-none py-3 sm:py-5 text-black font-semibold" [ngClass]="!last ? 'border-b border-light-blue' : ''" id="listbox-option-0" role="option">
                      <div class="flex items-center relative">
                        <div class="flex items-center gap-2 sm:gap-6">
                          <img src="../../../assets/images/club.png" alt="" class="size-8 shrink-0 rounded-full">
                          <div class="">
                            <h4 class="block truncate pb-1 text-sm">{{club.name}}</h4>
                            <span class="block truncate font-normal text-xs">{{club.city}}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  }@else {
                    <div
                      class="p-10 border-[1px] border-dashed border-light-blue max-w-[1118px] flex item-center justify-center rounded mt-[34px]">
                      <p class="text-xs text-black">No clubs found!</p>
                    </div>
                  }
                }
              </ul>
            </div>
          </div>

      </div>

      <ul class="list-none mb-0 space-x-1 flex items-center gap-0.5 sm:gap-1">
          <!-- plus Dropdown -->
          <li class="dropdown inline-block relative">
            <div class="relative inline-block text-left">
              <div>
                <button type="button" class="flex justify-center items-center rounded-full w-7 sm:w-8 h-7 sm:h-8 bg-darkBlue" id="menu-button" aria-expanded="true" aria-haspopup="true" (click)="toggleAddDropdown()">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="size-3">
                    <path d="M1 8H15" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    <path d="M8 15L8 1" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </button>
              </div>
          <div *ngIf="isAddDropdownOpen"
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
            role="menu" aria-orientation="vertical"
            aria-labelledby="menu-button" tabindex="-1">
            <div role="none">
              <a href="#"
                class="block px-4 py-3 text-xs text-gray-700 border-b border-light-blue"
                role="menuitem" tabindex="-1" id="menu-item-0">Add New
                League</a>
              <a href="#"
                class="block px-4 py-3 text-xs text-gray-700 border-b border-light-blue"
                role="menuitem" tabindex="-1" id="menu-item-1">Add New
                Series</a>
              <a href="#" class="block px-4 py-3 text-xs text-gray-700 "
                role="menuitem" tabindex="-1" id="menu-item-2">Add New Match</a>
                </div>
              </div>
            </div>

          </li>
          <!-- plus Dropdown -->

          <!-- Notification Dropdown -->
          <li class=" dropdown inline-block relative ">
              <button data-dropdown-toggle="dropdown" class="dropdown-toggle h-7 sm:h-8 w-7 sm:w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-white dark:bg-slate-800 dark:hover:bg-slate-700 border border-light-blue dark:border-gray-800 text-slate-900 dark:text-white rounded-full" type="button" (click)="notificationManu()">
                  <i data-feather="bell" class="h-4 w-4"></i>
                  <span class="absolute top-0 end-0 flex items-center justify-center bg-red-600 text-white text-[10px] font-bold rounded-full size-2 after:content-[''] after:absolute after:h-2 after:w-2 after:bg-red-600 after:top-0 after:end-0 after:rounded-full after:animate-ping"></span>
              </button>
              <!-- Dropdown menu -->
              <div class="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-64 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 hidden">
                  <span class="px-4 py-4 flex justify-between">
                      <span class="font-semibold">Notifications</span>
                      <span class="flex items-center justify-center bg-red-600/20 text-red-600 text-[10px] font-bold rounded-full w-5 max-h-5 ms-1">3</span>
                  </span>
                  <ul class="py-2 text-start h-64 border-t border-gray-100 dark:border-gray-800" data-simplebar>
                      <li>
                          <a routerLink="#!" class="block font-medium py-1.5 px-4">
                              <div class="flex items-center">
                                  <div class="h-10 w-10 rounded-md shadow shadow-indigo-600/10 dark:shadow-gray-700 bg-indigo-600/10 dark:bg-slate-800 text-indigo-600 dark:text-white flex items-center justify-center">
                                      <i data-feather="shopping-cart" class="h-4 w-4"></i>
                                  </div>
                                  <div class="ms-2">
                                      <span class="text-[15px] font-semibold block">Order Complete</span>
                                      <small class="text-slate-400">15 min ago</small>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a routerLink="#!" class="block font-medium py-1.5 px-4">
                              <div class="flex items-center">
                                  <img src="" class="h-10 w-10 rounded-md shadow dark:shadow-gray-700" alt="">
                                  <div class="ms-2">
                                      <span class="text-[15px] font-semibold block"><span class="font-bold">Message</span> from Luis</span>
                                      <small class="text-slate-400">1 hour ago</small>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a routerLink="#!" class="block font-medium py-1.5 px-4">
                              <div class="flex items-center">
                                  <div class="h-10 w-10 rounded-md shadow shadow-indigo-600/10 dark:shadow-gray-700 bg-indigo-600/10 dark:bg-slate-800 text-indigo-600 dark:text-white flex items-center justify-center">
                                      <i data-feather="dollar-sign" class="h-4 w-4"></i>
                                  </div>
                                  <div class="ms-2">
                                      <span class="text-[15px] font-semibold block"><span class="font-bold">One Refund Request</span></span>
                                      <small class="text-slate-400">2 hour ago</small>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a routerLink="#" class="block font-medium py-1.5 px-4">
                              <div class="flex items-center">
                                  <div class="h-10 w-10 rounded-md shadow shadow-indigo-600/10 dark:shadow-gray-700 bg-indigo-600/10 dark:bg-slate-800 text-indigo-600 dark:text-white flex items-center justify-center">
                                      <i data-feather="truck" class="h-4 w-4"></i>
                                  </div>
                                  <div class="ms-2">
                                      <span class="text-[15px] font-semibold block">Deliverd your Order</span>
                                      <small class="text-slate-400">Yesterday</small>
                                  </div>
                              </div>
                          </a>
                      </li>
                      <li>
                          <a routerLink="#" class="block font-medium py-1.5 px-4">
                              <div class="flex items-center">
                                  <img src="" class="h-10 w-10 rounded-md shadow dark:shadow-gray-700" alt="">
                                  <div class="ms-2">
                                      <span class="text-[15px] font-semibold block"><span class="font-bold">Cally</span> started following you</span>
                                      <small class="text-slate-400">2 days ago</small>
                                  </div>
                              </div>
                          </a>
                      </li>
                  </ul>
              </div>
              <!--end dropdown-->
          </li>
          <!-- Notification Dropdown -->

          <!-- User/Profile Dropdown -->
          <li class="dropdown inline-block relative border rounded-full border-light-blue">
              <button class="dropdown-toggle flex" type="button">
                  <span class="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-50 dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700 border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-white rounded-full">
                    <img src="assets/images/client/05.jpg" class="rounded-full" alt="" (click)="userDropdown()">
                  </span>
                  <span class="text-light-grey text-sm m-1 sm:inline-block hidden text-center">{{fullName}}</span>
              </button>
              <!-- Dropdown menu -->
              <div class="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-44 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 hidden" [ngClass]="{'hidden' : isUser }">
                  <ul class="py-2 text-start">
                      <li>
                          <a routerLink="/user/profile" class="block font-medium text-sm py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"><i class="uil uil-user me-2"></i>Profile</a>
                      </li>
                      <li class="border-t border-gray-100 dark:border-gray-800 my-2"></li>
                      <li>
                          <a (click)="logout()" class="block font-medium text-sm py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"><i class="uil uil-sign-out-alt me-2"></i>Logout</a>
                      </li>
                  </ul>
              </div>
              <!--end dropdown-->
          </li>
          <!-- User/Profile Dropdown -->
      </ul>
  </div>
</div>
