<ng-container *ngIf="showConfirmationPopup">
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog"
  aria-modal="true">
  <div class="fixed inset-0 bg-gray-500/75 transition-opacity"
    aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto font-montserrat">
    <div
      class="flex min-h-full justify-center p-4 text-center items-center sm:p-0 layout-overlay">
      <div
        class="relative transform overflow-hidden bg-white shadow-xl transition-all sm:my-8 max-w-[440px]">
        <div class="flex justify-end absolute right-0 p-2" (click)="onClosePopup()">
          <svg
            class="cursor-pointer size-5 md:size-7"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 32 32"
          >
            <path
              fill="#a4a4a4"
              d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"
            />
          </svg>
        </div>
        <div
          class="bg-white py-5 md:py-10 px-5 md:px-[50px] h-full flex flex-wrap items-center justify-center border border-light-grey">
          <img src="../../../../assets/images/mail.png" alt class="max-w-[100px] md:max-w-full">
          <h3
            class="text-lg font-semibold text-black"
            id="modal-title">Email has been sent Successfully to
            {{emailId}}</h3>
          <p
            class="text-sm text-light-grey my-4 md:my-8 md:leading-6">
            {{message}}
          </p>
          <div class="flex gap-2">
            <cricteams-dy-button type="button" text="Check your Email" [class]="btnClass"
              (click)="goToInbox()"></cricteams-dy-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>
