import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  private restInstance;
  private skipInterceptor = false;

  constructor(private loadingService: LoadingService) {
    // Create a new Axios instance with the base URL
    this.restInstance = axios.create({
      baseURL: environment.baseUrl, // Replace with your actual base URL
    });

    // Optionally, set default headers, interceptors, etc.
    //this.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer your-token'; // Example: Adding authorization headers
    this.initializeInterceptor();
  }

  private initializeInterceptor() {
    this.restInstance.interceptors.request.use(
      (config) => {
        if (!this.skipInterceptor) {
          this.loadingService.show();
        }
        return config;
      },
      (error) => {
        this.loadingService.hide();
        return Promise.reject(error);
      }
    );

    this.restInstance.interceptors.response.use(
      (response) => {
        if (!this.skipInterceptor) {
          this.loadingService.hide();
        }
        return response;
      },
      (error) => {
        this.loadingService.hide();
        return Promise.reject(error);
      }
    );
  }

  // Method to disable interceptors temporarily
  public skipRequestInterceptor(shouldSkip: boolean) {
    this.skipInterceptor = shouldSkip;
  }

  // Expose a method to use the Axios instance
  public getRestInstance() {
    return this.restInstance;
  }
}
