import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter } from 'rxjs';
import { DetailViewComponent } from '../components/detail-view/detail-view.component';
import { FooterComponent } from "../components/footer/footer-dashboard/footer.component";
import { HeaderComponent } from "../components/header/header.component";
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { routeUrls } from '../constants/constants';

@Component({
  selector: 'cricteams-layout',
  standalone: true,
  imports: [CommonModule,RouterModule, SidebarComponent, FooterComponent, HeaderComponent, DetailViewComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit {
  public activeSidebar = true;
  protected showDetailPage = false;

  constructor(private router: Router){}

  ngOnInit(): void {
    this.checkRoute();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkRoute();
    });
  }

  checkRoute(): void {
    this.showDetailPage = !(this.router.url === `/${routeUrls.DASHBOARD}` || this.router.url === `/${routeUrls.USER}/${routeUrls.PROFILE}` || this.router.url === `/${routeUrls.SETTINGS}`
      || this.router.url === `/${routeUrls.PLAYERS}` || this.router.url === `/${routeUrls.PRACTICES}` || this.router.url === `/${routeUrls.ADD_PRACTICES}` || this.router.url === `/${routeUrls.LIST_PRACTICES}` || this.router.url === `/${routeUrls.MATCHES}` ||this.router.url === `/${routeUrls.LIST_MATCHES}` || this.router.url === `/${routeUrls.ADD_MATCHES}`);
  }

  toggleClass() {
    this.activeSidebar = !this.activeSidebar;
  }
}
