<div *ngIf="isError"
  class="bg-light-red border border-light-red text-light-red px-4 py-3 rounded max-w-[65.1%] w-full alert-error"
  role="alert">
  <span class="block sm:inline font-semibold text-xs font-montserrat">{{
    message
    }}</span>
</div>
<div *ngIf="isSuccess"
  class="bg-light-green border border-light-green text-light-green px-4 py-3 rounded max-w-[65.1%] w-full alert-success"
  role="alert">
  <span class="block sm:inline font-semibold text-xs font-montserrat">{{
    message
    }}</span>
</div>
