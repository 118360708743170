import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, output, TemplateRef } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DyButtonComponent } from '../../../components/form-fields/dy-button/dy-button.component';
import { DyDatePickerComponent } from '../../../components/form-fields/dy-date-picker/dy-date-picker.component';
import { DyInputComponent } from '../../../components/form-fields/dy-input/dy-input.component';
import { routeUrls } from '../../../constants/constants';
import {
  ComponentTypes,
  FieldConfig,
} from '../../../interfaces/dynamic.interface';
import { Tournament } from '../../../models/club/club-tournament.model';
import { League } from '../../../models/league/league-info.model';
import { DynamicService } from '../../../services/dynamic.service';
import { UserService } from '../../../services/user.service';
import { LeagueModalComponent } from '../../leagues/league-modal/league-modal.component';
import { TournamentService } from './../../../services/tournament.service';
import { AlertBannerComponent } from "../../../components/alert-banner/alert-banner.component";
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'cricteams-add-tournament',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DyButtonComponent,
    RouterModule,
    DyInputComponent,
    DyDatePickerComponent,
    BsDatepickerModule,
    TooltipModule,
    LeagueModalComponent,
    AlertBannerComponent
],
  templateUrl: './add-tournament.component.html',
  styleUrl: './add-tournament.component.scss',
  providers: [BsModalService],
})
export class AddTournamentComponent implements OnInit {
  public cancelBtnClass =
    'text-dark-blue border border-dark-blue text-xs font-semibold p-2 xl:py-3 sm:px-5 h-[36px] sm:h-[40px] w-fit hover:text-white hover:bg-darkBlue transition duration-700 ease-in-out';
  public saveBtnClass =
    'text-white text-xs bg-dark-blue font-semibold px-5 sm:p-2 md:p-3 h-[36px] sm:h-[40px] md:w-[100px] transition duration-700 ease-in-out';
  public labelClass = 'flex items-center text-xs text-light-grey font-medium mb-2';
  public inputClass =
    'bg-white rounded border-[1px] border-light-blue w-full p-2 md:h-[40px] text-xs text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none';
  public constants = routeUrls;
  public isTournametDataAvailable = false;
  public message!: string;
  public isSuccess!: boolean;
  public isError!: boolean;
  public dyForm!: FormGroup;
  public userBaseInfo!: any;
  public cUserId!: string;
  public vcUserId!: string;
  public leagueData!: League;

  @Input() isModal!: boolean;
  @Input() isEdit!: boolean;
  @Input() selectedTournament!: Tournament;
  onCloseModal = output<boolean>();
  modalRef!: BsModalRef;

  constructor(
    private dynamicService: DynamicService,
    private router: Router,
    private modalService: BsModalService,
    private userService: UserService,
    private tournamentService: TournamentService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.createForm();

    if (this.dynamicService.dyForm) {
      this.setValue(this.dynamicService.dyForm);
    }

    if (this.isTournametDataAvailable) {
      this.router.navigateByUrl(this.constants.LIST_TOURNAMENTS);
    }

    if (this.selectedTournament) {
      this.dyForm.patchValue({
        tournamentName: this.selectedTournament.name,
        leagueName: this.selectedTournament.league.name,
        startDate: new Date(this.selectedTournament.startDate),
        endDate: new Date(this.selectedTournament.endDate),
      });
    }
  }

  createForm() {
    this.dyForm = this.dynamicService.createFormControl([
      this.tournamentName,
      this.leagueName,
      this.startDate,
      this.endDate,
    ]);
  }

  public tournamentName: FieldConfig = {
    label: 'Tournament Name',
    name: 'tournamentName',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Enter tournament name',
  };
  public leagueName: FieldConfig = {
    label: 'League Name',
    name: 'leagueName',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'Select league',
  };
  public startDate: FieldConfig = {
    label: 'Start Date',
    name: 'startDate',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'MM/DD/YYYY',
  };
  public endDate: FieldConfig = {
    label: 'End Date',
    name: 'endDate',
    componentType: ComponentTypes.Text,
    required: true,
    placeholder: 'MM/DD/YYYY',
  };

  public onSubmit() {
    if (this.dyForm.valid) {
      this.saveTournament();
    }
  }

  async saveTournament() {
    const clubId = localStorage.getItem('currentClubId') || '';
    if (clubId) {
      const user = await this.userService.getUserInfoFromLocalStorage();
      let payload = {
        ...this.dyForm.value,
        startDate: this.commonService.getFormattedDate(this.dyForm.value['startDate']),
        endDate: this.commonService.getFormattedDate(this.dyForm.value['endDate']),
        captainUserId: user?.user.id,
        vcUserId: user?.user.id,
      };

      if (this.isEdit) {
        payload = { ...payload };
      } else {
        payload = {
          ...payload,
          clubId: clubId,
          leagueId: this.leagueData
            ? this.leagueData.id
            : this.selectedTournament.league.id,
        };
      }
      const tournamentId = this.selectedTournament ? this.selectedTournament.id : '';

      (this.isEdit
        ? this.tournamentService.editTournament(tournamentId, payload)
        : this.tournamentService.saveTournament(payload)
      ).then((response: any) => {
        if (response?.isSuccess) {
          this.isSuccess = true;
          this.message = response.message;
          this.dyForm.reset();
          this.dyForm.setErrors(null);
          this.updateTournamentList(clubId);
          if (!this.isModal) {
            this.router.navigateByUrl(this.constants.LIST_TOURNAMENTS);
          }
          this.onCloseModal.emit(true);
        } else {
          if (response.error.code === 400) {
            this.isError = true;
            this.message = response.error.description;
            this.dyForm.reset({
              tournamentName: this.dyForm.get('tournamentName')?.value,
              leagueName: this.dyForm.get('leagueName')?.value,
            });
            this.dyForm.setErrors(null);
          } else {
            this.isError = true;
            this.message = response.message;
            this.dyForm.reset();
            this.dyForm.setErrors(null);
          }
        }
      });
    }
  }

  async updateTournamentList(clubId: string) {
    const tournaments = await this.tournamentService.getClubTournaments(
      clubId ?? ''
    );
    if(tournaments){
      this.tournamentService.isTournametDataAvailable.next(false)
      this.tournamentService.tournamentList.next(tournaments)
    }
  }

  public setValue(dyForm: FormGroup) {
    this.dyForm.setValue({
      tournamentName: dyForm.value['tournamentName'],
      leagueName: dyForm.value['leagueName'],
      startDate: dyForm.value['startDate'],
      endDate: dyForm.value['endDate'],
    });
  }

  public setLeagueValue(league: League) {
    this.leagueData = league;
    if (league) {
      this.dyForm.patchValue({
        leagueName: league?.name,
      });
    }
  }

  public handleCancle() {
    if (this.isModal) {
      this.onCloseModal.emit(true);
    } else {
      this.dyForm.reset();
      this.router.navigateByUrl(this.constants.TOURNAMENTS);
      this.isSuccess = false;
      this.message = '';
    }
  }

  public openLeagueSelectionModal(template: TemplateRef<HTMLElement>) {
    this.modalRef = this.modalService.show(template);
  }

  public handleClose(event: boolean) {
    if (event) {
      this.modalRef.hide();
    }
  }
}
