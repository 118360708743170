@if (isModal) {
<div
  class="relative z-[9999] font-montserrat"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="fixed inset-0 bg-gray-500/75 transition-opacity"
    aria-hidden="true"
  ></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto flex items-center justify-center">
    <ng-container [ngTemplateOutlet]="tournamentForm"></ng-container>
  </div>
</div>
} @else {
    <ng-container [ngTemplateOutlet]="tournamentForm"></ng-container>
}

<ng-template #tournamentForm>
  <div
    class="bg-white rounded-md border-[1px] border-light-grey p-4 md:p-5 font-montserrat"
    [ngClass]="{ 'w-full h-fit max-w-[400px] sm:max-w-[800px]': isModal }"
  >
    <h4 class="font-semibold text-black text-sm md:text-base">
      {{ isEdit ? 'Edit' : 'Add' }} Tournament
    </h4>
    <div class="mt-4 xl:mt-6">
      <form
        [formGroup]="dyForm"
        #form="ngForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="grid lg:grid-cols-2 gap-0 sm:gap-5">
          <div class="lg:col-span-1 mb-5 sm:mb-7 relative">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="tournamentName"
              [group]="dyForm"
            ></cricteams-dy-input>
          </div>
          <div class="lg:col-span-1 mb-5 sm:mb-7 relative">
            <cricteams-dy-input
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="leagueName"
              [group]="dyForm"
              [isDisabled]="isEdit ? true : false"
              (click)="!isEdit && openLeagueSelectionModal(leagueSelectionModal)"
              [isReadOnly]="true"
            ></cricteams-dy-input>
          </div>
        </div>

        <div class="grid lg:grid-cols-2 lg:gap-5 items-center">
          <div class="lg:col-span-1 mb-5 sm:mb-7 relative">
            <cricteams-dy-date-picker
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="startDate"
              [group]="dyForm"
              [isToolTip]="false"
            >
            </cricteams-dy-date-picker>
          </div>
          <div class="lg:col-span-1 mb-5 sm:mb-7 relative">
            <cricteams-dy-date-picker
              [labelClass]="labelClass"
              [inputClass]="inputClass"
              [form]="form"
              [field]="endDate"
              [group]="dyForm"
              [isToolTip]="true"
            >
            </cricteams-dy-date-picker>
          </div>
        </div>

        <div class="text-right flex justify-end gap-2">
          <cricteams-dy-button
            (click)="handleCancle()"
            text="Cancel"
            [class]="cancelBtnClass"
          ></cricteams-dy-button>
          <cricteams-dy-button
            type="submit"
            text="{{ isEdit ? 'Update' : 'Save' }}"
            [disabled]="dyForm.invalid"
            [class]="saveBtnClass"
            [conditionalClass]="{
              'bg-light-blue': dyForm.invalid,
              'bg-dark-blue cursor-pointer': dyForm.valid
            }"
          ></cricteams-dy-button>
        </div>
      </form>
    </div>
  </div>
  <cricteams-alert-banner [isError]="isError" [isSuccess]="isSuccess" [message]="message" />
</ng-template>

<ng-template #leagueSelectionModal>
  <cricteams-league-modal (onLeagueModalClose)="handleClose($event)" (getLeagueValue)="setLeagueValue($event)"></cricteams-league-modal>
</ng-template>
