import { CommonModule } from '@angular/common';
import { Component, OnInit, output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { ApiResponse } from '../../../models/api-response';
import { League } from '../../../models/league/league-info.model';
import { CommonService } from '../../../services/common.service';
import { LeagueService } from '../../../services/league.service';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'cricteams-league-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './league-modal.component.html',
  styleUrl: './league-modal.component.scss',
})
export class LeagueModalComponent implements OnInit{
  public isClubDropdownOpen = false;
  public isAddDropdownOpen = false;
  public allLeagues: League[] = [];
  public allLeaguesBackup: League[] = [];
  public myLeagues: League[] = [];
  public stateList: string[] = [];
  public stateListBackup:string[]= [];
  public leagueSearchControl: FormControl = new FormControl('');
  public stateSearchControl: FormControl = new FormControl('');
  public selectedState!: string | undefined;
  public onLeagueModalClose = output<boolean>();
  public getLeagueValue = output<League>();

  constructor(private leagueService: LeagueService, protected commonService: CommonService, private stateService : StateService){}

  ngOnInit(): void {
   this.getLeagues();
   this.getMyLagues();
   this.getStates();

   this.leagueSearchControl.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
    this.filterLeagues(value);
   })
   this.stateSearchControl.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
    this.filterStates(value);
   })

  }

  getLeagues(){
    this.leagueService.getLeagues()
    .then((response: ApiResponse<League[]> | unknown)=>{
      const apiResponse = response as ApiResponse<League[]>;
      if (apiResponse.data?.length) {
        this.allLeagues = apiResponse.data;
        this.allLeaguesBackup = this.allLeagues;
      }
      this.setAllLeague();
    })
  }

  getMyLagues(){
    const clubId:string|null = localStorage.getItem('currentClubId');

    this.leagueService.getMyLeagues(clubId as string)
    .then((response:ApiResponse<League[]> | unknown) =>{
      this.myLeagues = response as League[];
      this.setAllLeague();
    })
  }

  getStates(){
    this.stateList = this.stateService.getStates().filter((state)=> state !== 'Select One');
    this.stateListBackup = this.stateList;
  }

  setAllLeague(){
    if(this.myLeagues && this.allLeagues){
      const remainingLeagues = this.allLeagues.filter(league =>
        !this.myLeagues.some(myLeague => myLeague.id === league.id)
      );
      this.allLeagues = remainingLeagues;
      this.allLeaguesBackup = remainingLeagues;
    }
  }

  onSelectLeague(league:League){
    this.getLeagueValue.emit(league);
    this.handleClose();
  }

  toggleClubDropdown() {
    this.isClubDropdownOpen = !this.isClubDropdownOpen;
  }

  toggleAddDropdown() {
    this.isAddDropdownOpen = !this.isAddDropdownOpen;
  }

  handleClose() {
    this.onLeagueModalClose.emit(true);
  }

  filterLeagues(value: string){

    if(value){
      this.allLeagues = (this.selectedState ? this.allLeagues : this.allLeaguesBackup).filter((league: League) => {
        return league.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
               league.city.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
               league.state.toLocaleLowerCase().includes(value.toLocaleLowerCase());
      });
    }else{
      if(this.selectedState){
        this.allLeagues = this.allLeaguesBackup.filter((league: League) => league.state === this.selectedState);
      }else{
        this.allLeagues = this.allLeaguesBackup;
      }
    }
  }

  filterStates(value:string){
    if(value){
      this.stateList = this.stateList.filter((state:string)=>{
        return state.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      })
    }else{
      this.stateList = this.stateListBackup
    }
  }

  selectState(state: string) {
    this.selectedState = state;
    this.filterLeagues(state);
  }

  resetStateSelection(event: Event) {
    event.stopPropagation();
    this.stateSearchControl.setValue('');
    this.selectedState = undefined;
    this.isClubDropdownOpen = false;
    if(!this.leagueSearchControl.value){
      this.allLeagues = this.allLeaguesBackup;
    }
  }
}
