import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  generateBadge(name: string): string {
    const words = name.split(' ');
    let badge = '';
    if (words.length === 1) {
      const word = words[0];
      badge += word.substring(0, 2).toUpperCase();
    } else {
      for (let i = 0; i < 2; i++) {
        badge += words[i].charAt(0).toUpperCase();
      }
    }
    return badge;
  }

  getFormattedDate(date: Date): string {
    const padZero = (num: number) => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }

  calculateDuration(startDateTime: Date, endDateTime: Date){
    const startHour = startDateTime.getHours();
    const endHour = endDateTime.getHours();

    return endHour - startHour;
  }
}
