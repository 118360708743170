import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { routeUrls } from '../../constants/constants';
import { UserService } from '../../services/user.service';
import { SearchPipe } from '../../pipes/search.pipe';
import { FormsModule } from '@angular/forms';
import { Club } from '../../models/club/club-info.model';

@Component({
  selector: 'cricteams-header',
  standalone: true,
  imports: [CommonModule, RouterLink, SearchPipe, FormsModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  protected constants = routeUrls;
  isClubDropdownOpen = false;
  isAddDropdownOpen = false;
  clubName = '';
  fullName = '';
  displayClubDropdown = false;
  public clubsList: Club[] = [];
  public activeClub: Club | null = null;
  public clubSearchKeyword = '';
  @Output() toggleClass = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {
    this.setUserInfo();
  }

  ngOnInit(): void {
    this.getClubs();
  }

  private async setUserInfo() {
    const user = await this.userService.getUserInfoFromLocalStorage();
    if (user) {
      const clubId = user.clubs.find((club:Club) => club.currentUserRole === 0)?.id;
      localStorage.setItem('currentClubId', clubId ? clubId : user.clubs[0].id);
      this.displayClubDropdown = user.clubs.length > 1;
      this.fullName = user.user.fullName;
    }
  }

  emitToggleClassEvent() {
    this.toggleClass.emit();
  }

  isNotification = true;

  notificationManu() {
    this.isNotification = !this.isNotification;
  }

  isUser = true;
  userDropdown() {
    this.isUser = !this.isUser;
  }

  logout() {
    this.authService.logout();
    localStorage.removeItem('access_token');
    localStorage.clear();
    this.router.navigateByUrl('home');
  }

  toggleClubDropdown() {
    this.isClubDropdownOpen = !this.isClubDropdownOpen;
  }

  toggleAddDropdown() {
    this.isAddDropdownOpen = !this.isAddDropdownOpen;
  }

  private async getClubs() {
    const clubId = localStorage.getItem('currentClubId');
    const userInfo = localStorage.getItem('userBaseInfo');
    if (userInfo) {
      const clubData = JSON.parse(userInfo);
      this.clubsList = clubData.clubs.filter((club: Club) => club.id !== clubId);
      this.clubName = clubData.clubs.find((club: Club) => clubId == club.id)?.name ?? '';
    }
  }

  onClubSelect(selectedClub: Club) {
    this.activeClub = selectedClub;
    localStorage.setItem('currentClubId', this.activeClub.id);
    this.clubName = this.activeClub.name;
    this.getClubs();
    this.toggleClubDropdown();
    this.router.navigateByUrl(this.constants.ROOT);
    this.userService.isUserManagerOrCaptain();
  }
}
