import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  standalone: true,
})
export class SearchPipe implements PipeTransform {
  transform<T>(items: T[], searchTerm: string, ...keys: string[]): T[] {
    if (!items || !searchTerm) {
      return items;
    }

    const lowerCaseTerm = searchTerm.toLowerCase();

    return items.filter((item) =>
      keys.some((key) => {
        const value = this.getValueByKey(item, key);
        return value?.toLowerCase().includes(lowerCaseTerm);
      })
    );
  }

  private getValueByKey(item: any, key: string): string {
    return key.split('.').reduce((obj, keyPart) => obj?.[keyPart], item) || '';
  }
}
