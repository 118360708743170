import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { FieldConfig } from '../../../interfaces/dynamic.interface';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'cricteams-dy-date-picker',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, BsDatepickerModule,
      TooltipModule],
  templateUrl: './dy-date-picker.component.html',
  styleUrl: './dy-date-picker.component.scss',
})
export class DyDatePickerComponent {
    @Input() field!: FieldConfig;
    @Input() group!: FormGroup;
    @Input() form!: FormGroupDirective;
    @Input() inputClass!: string;
    @Input() labelClass!: string;
    @Input() isToolTip!: boolean;

    minDate: Date = new Date();
    constructor() {
      this.minDate.setHours(0, 0, 0, 0);
    }
}
