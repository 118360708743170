import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { routeUrls } from '../../../constants/constants';
import { Tournament } from '../../../models/club/club-tournament.model';
import { Match } from '../../../models/match/match.model';
import { SearchPipe } from '../../../pipes/search.pipe';
import { CommonService } from '../../../services/common.service';
import { TournamentService } from '../../../services/tournament.service';
import { AddTournamentComponent } from "../add-tournament/add-tournament.component";

@Component({
  selector: 'cricteams-tournaments-details',
  standalone: true,
  imports: [CommonModule, AddTournamentComponent, RouterLink, FormsModule, SearchPipe],
  templateUrl: './tournaments-details.component.html',
  styleUrl: './tournaments-details.component.scss',
  providers: [BsModalService],
})
export class TournamentsDetailsComponent implements OnInit, OnDestroy {
  public labelClass = 'hidden mb-2.5 text-sm text-light-grey font-medium';
  public inputClass =
    'bg-white rounded border-[1px] border-light-blue w-full p-3 h-[34px] sm:h-[40px] text-sm text-black font-semibold placeholder:font-normal placeholder:text-xs placeholder:text-light-grey focus:outline-none';
  public btnClass = 'text-white text-sm font-medium p-3 h-[42px] w-[142px]';
  public constants = routeUrls;
  public modalRef!: BsModalRef;
  public selectedTournament!: Tournament;
  public tournamentsList: Tournament[] = [];
  public tournamentsListBackup: Tournament[] = [];
  public tournamentSearchKeyword = '';
  public dyForm!: FormGroup;
  public isTournametDataAvailable = false;
  public TournamentStartYears: number[] = [];
  public MatchStartYears: number[] = [];
  public selectedYear!:string;
  public subscription!: Subscription;
  public matchList: Match[] = [];
  public matchListBackUp: Match[] = [];

  constructor(
    private modalService: BsModalService,
    private tournamentService: TournamentService,
    protected commonService: CommonService
  ) {}

  ngOnInit(): void {
    if(!this.subscription){
      this.subscription = this.tournamentService.selectedTournament.
      subscribe(
        (tournament: Tournament | undefined) => {
          if (tournament) {
            this.selectedTournament = tournament;
            this.tournamentService.isTournametDataAvailable.next(true);
            this.isTournametDataAvailable = true;
            this.getMatches();
          }
        }
      );
    }

    this.tournamentService.tournamentList.subscribe(
      (tournaments: Tournament[]) => {
        if (tournaments && tournaments.length) {
          const sortedTournaments = [...tournaments].sort((a, b) => {
            return (
              new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
            );
          });
          this.tournamentsList = sortedTournaments;
          this.tournamentsListBackup = this.tournamentsList;
          this.TournamentStartYears = this.getStartYears();
        }else {
          this.tournamentsList = [];
        }
      }
    );
  }

  getStartYears(){
    const years = this.tournamentsList.map(item => new Date(item.startDate).getFullYear());
    return [...new Set(years)];
  }

  getStartYearsOfMatches(){
    const years = this.matchList.map(item => new Date(item.startDateTime).getFullYear());
    return [...new Set(years)]
  }

  handleEditTournament(template: TemplateRef<HTMLElement>) {
    this.modalRef = this.modalService.show(template);
  }

  handleClose(event: boolean) {
    if (event) {
      this.modalRef.hide();
    }
  }

  filterTournaments(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedYear = selectElement.value;
    if(this.isTournametDataAvailable){
      if(this.selectedYear === 'all' || this.selectedYear == ''){
        this.matchList = this.matchListBackUp;
      }else{
        this.matchList = this.matchListBackUp.filter(match => {
          const year = new Date(match.startDateTime).getFullYear();
          return year.toString() === this.selectedYear;
        });
      }
    }else{
      if(this.selectedYear === 'all' || this.selectedYear == ''){
        this.tournamentsList = this.tournamentsListBackup;
      }else{
        this.tournamentsList = this.tournamentsListBackup.filter(tournament => {
          const year = new Date(tournament.startDate).getFullYear();
          return year.toString() === this.selectedYear;
        });
      }
    }

  }

  closeDetailView(){
    this.isTournametDataAvailable = false;
    this.selectedYear = 'all';
    this.tournamentsList = this.tournamentsListBackup;
  }

  handleTournamentSelection(tournament: Tournament){
    this.tournamentService.selectedTournament.next(tournament);
  }

  async getMatches(){
    const tournamentId = this.selectedTournament.id;
    const matches = await this.tournamentService.getTournamentMatches(tournamentId);
    if(matches && matches.length){
      this.matchList = matches
      this.matchListBackUp = this.matchList;
      this.MatchStartYears = this.getStartYearsOfMatches();
    }
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}
