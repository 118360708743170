import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { ApiResponse } from '../models/api-response';
import { Tournament } from '../models/club/club-tournament.model';
import { Match } from '../models/match/match.model';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class TournamentService {
  selectedTournament: Subject<Tournament | undefined> = new Subject();
  isTournametDataAvailable: Subject<boolean> =  new Subject();
  tournamentList: BehaviorSubject<Tournament[]> = new BehaviorSubject<Tournament[]>([]);

  constructor(private restService: RestService,private auth:AuthService) { }


  public async saveTournament(data: Record<string, string | number | boolean | unknown>): Promise<unknown> {
    try {
        const apiUrl = '/tournaments';
        const restInstance = this.restService.getRestInstance();
        const token = await firstValueFrom(this.auth.getAccessTokenSilently());
        restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await restInstance.post<ApiResponse<unknown>>(apiUrl, {
            clubId: data['clubId'],
            leagueId: data['leagueId'],
            captainUserId: data['captainUserId'],
            vcUserId: data['vcUserId'],
            name: data['tournamentName'],
            startDate: data['startDate'],
            endDate: data['endDate'],
        });
        return response.data;
    } catch (error) {
        return error;
    }
  }

  public async editTournament(tournamentId: string,data: Record<string, string | number | boolean | unknown>): Promise<unknown> {
    try {
        const apiUrl = '/tournaments';
        const restInstance = this.restService.getRestInstance();
        const token = await firstValueFrom(this.auth.getAccessTokenSilently());
        restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await restInstance.put<ApiResponse<unknown>>( `${apiUrl}/${tournamentId}`, {
            captainUserId: data['captainUserId'],
            vcUserId: data['vcUserId'],
            name: data['tournamentName'],
            startDate: data['startDate'],
            endDate: data['endDate'],
            players: {}
        });
        return response.data;
    } catch (error) {
        return error;
    }
  }


  public async getClubTournaments(clubId: string): Promise<Array<Tournament> | null> {
    try {
      const apiUrl = '/clubs/tournaments';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(
        this.auth.getAccessTokenSilently()
      );
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Array<Tournament>>>(
        `${apiUrl}/${clubId}`
      );
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      return null;
    }
  }
  public async getTournamentMatches(tournamentId: string): Promise<Array<Match> | null> {
    try {
      const apiUrl = '/tournaments/matches';
      const restInstance = this.restService.getRestInstance();
      const token = await firstValueFrom(
        this.auth.getAccessTokenSilently()
      );
      restInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await restInstance.get<ApiResponse<Array<Match>>>(
        `${apiUrl}/${tournamentId}`
      );
      return response.data.isSuccess ? response.data.data ?? null : null;
    } catch (error) {
      return null;
    }
  }
}
