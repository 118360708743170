import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  message: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isSuccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showSuccess(message: string) {
    this.message.next(message);
    this.isSuccess.next(true);
    setTimeout(() => {
      this.isSuccess.next(false);
    }, 1500);
  }

  showError(message: string) {
    this.message.next(message);
    this.isError.next(true);
    setTimeout(() => {
      this.isError.next(false);
    }, 1500);
  }
}
