<cricteams-app-nav-light/>

<section class="bg-cover bg-hero-banner relative before:content-[''] before:absolute xl:before:-top-[30%] lg:before:-top-[50%] sm:before:-top-[80%] before:-top-[90%] before:-start-80 before:end-0 before:w-[140rem] before:h-[65rem] ltr:before:-rotate-12 rtl:before:rotate-12 before:z-1 items-center overflow-hidden">
    <div class="container relative z-2 pb-20">
        <div class="grid grid-cols-1 md:mt-44 mt-32 text-center">
            <div class="wow animate__animated animate__fadeIn">
                <h4 class="text-slate-50 font-bold lg:leading-normal leading-normal text-4xl lg:text-4xl mb-5">ULTIMATE!! Cricket Team Management Platform</h4>
               <p class="text-white lg:text-2xl max-w-xxl mx-auto">Crickteams is the next generation cricket technology platform that helps you balances cricketing experience with quality personal time. Whether you are admin, captain or player!</p>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section>


<section class="relative md:py-12 py-12 overflow-hidden">
    <div class="container relative">
        <div class="grid grid-cols-1 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Why CrickTeams ?</h3>
        </div><!--end grid-->
        <div class="grid md:grid-cols-2 grid-cols-1 mt-10 items-center gap-[30px] wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <div class="lg:me-8 ">
                <h4 class="mb-4 text-2xl leading-normal font-medium">Comprehensive Team Management <br> for crick-life blance</h4>
                <p class="text-slate-400"> All-in-one solution for every aspect of cricket team management. From practice sessions and match schedules to financial tracking and communication, we've got you covered.
                </p>
                <ul class="list-none text-slate-400 mt-4">
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Manage practice and match schedules</li>
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Manage players and their availability</li>
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Manage player payments and team budgets</li>
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Mobile App, to access crickteams anywhere</li>
                </ul>
            </div>
            <div class="lg:me-8 order-2 md:order-1">
                <h4 class="mb-4 text-2xl leading-normal font-medium">Attendance Made Simple, with automated reminders</h4>
                <p class="text-slate-400">Easily manage attendance for practices and matches with our intuitive polling system. Ensure that everyone is on the same page without the hassle of manual tracking.</p>
                <ul class="list-none text-slate-400 mt-4">
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Manage league and practice schedule</li>
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Personalized views for players to manage their availablity </li>
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Automated reminders to capture availability</li>
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Templated or instant polls</li>
                </ul>
            </div>
            <div class="lg:me-8 order-2 md:order-1">
                <h4 class="mb-4 text-2xl leading-normal font-medium">Instant Annoucements</h4>
                <p class="text-slate-400">Stay connected with your team through real-time announcements. Share updates, news, and important information effortlessly, keeping everyone informed and engaged.</p>
                <ul class="list-none text-slate-400 mt-4">
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Just like social network feed!</li>
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Share scoreboard urls, pictures, fun facts</li>
                </ul>
            </div>
            <div class="lg:me-8 order-2 md:order-1">
                <h4 class="mb-4 text-2xl leading-normal font-medium">Player-Centric Cricket History</h4>
                <p class="text-slate-400">Empower players to track their cricketing journey, including stats, achievements, and milestones. Encourage personal growth and performance improvement.</p>
                <ul class="list-none text-slate-400 mt-4">
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Build your personal cricketing profile at one place</li>
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Mange personal match stats</li>
                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>Manage titles and achievements</li>
                </ul>
            </div>
        </div>
    </div><!--end container-->
    <div class="container relative md:mt-12 mt-12">
        <cricteams-app-key-features/>
     </div><!--end contanier-->
</section>
<section id="key-features" class="relative">
    <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Features by Persona</h3>
        </div>
        <div class="grid lg:grid-cols-4 grid-cols-1 items-center gap-[30px] wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <div class="lg:col-span-4">
                <div class="lg:ms-8">
                    <div class="grid md:grid-cols-4 grid-cols-1 md:gap-12 gap-[16px]">
                        <div class="group border-b-[3px] dark:border-gray-700 relative shadow dark:shadow-gray-800 rounded-md md:scale-110 z-3 bg-white dark:bg-slate-900 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="p-6 py-8">
                                <h6 class="font-bold uppercase mb-5 text-indigo-600">Manager</h6>
                                <ul class="list-none text-slate-400">
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Manage Seasons, Leagues & Conferences</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Personalized Dashboard</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Team Gear & Ground Management</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Manage Finances</li>
                                </ul>
                            </div>
                        </div>
                        <div class="group border-b-[3px] dark:border-gray-700 relative shadow dark:shadow-gray-800 rounded-md md:scale-110 z-3 bg-white dark:bg-slate-900 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="p-6 py-8">
                                <h6 class="font-bold uppercase mb-5 text-indigo-600">Captain & Vice-Captain</h6>
                                <ul class="list-none text-slate-400">
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Player Management</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Matches & Schedules</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Manage Practice Sessions</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Manage Umpiring Schedules</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Manage Polls and Team Announcements</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Captain Dashboard</li>
                                </ul>
                            </div>
                        </div>
                        <div class="group border-b-[3px] dark:border-gray-700 relative shadow dark:shadow-gray-800 rounded-md md:scale-110 z-3 bg-white dark:bg-slate-900 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="p-6 py-8">
                                <h6 class="font-bold uppercase mb-5 text-indigo-600">Player</h6>
                                <ul class="list-none text-slate-400">
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Search nearby cricket clubs</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Easy player onboarding</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> View schedules in personalized view</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Manage personal stats</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Message captain directly</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Group Chat with the team</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Track your cricketing journey</li>
                                </ul>
                            </div>
                        </div>
                        <div class="group border-b-[3px] dark:border-gray-700 relative shadow dark:shadow-gray-800 rounded-md md:scale-110 z-3 bg-white dark:bg-slate-900 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="p-6 py-8">
                                <h6 class="font-bold uppercase mb-5 text-indigo-600">CrickTeams Bot</h6>
                                <ul class="list-none text-slate-400">
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Automated Practice Reminders</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Automated Match Reminders</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Automated Payment Reminders</li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Automated Selected Roster Notification </li>
                                    <li class="mb-1 flex ms-0"><i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Umpiring Request Reminders </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end grid-->
    </div><!--end container-->
</section>
<section id="contact" class="relative md:py-12 py-12 overflow-hidden">
    <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <p class="text-slate-400 mx-auto">We're excited to offer you a limited free subscription to our platform. Just fill out the form below, and enjoy all core features without any cost for a limited time. Manage chats, polls, reminders, schedules, player availability, payments, and budgets effortlessly with our solution. </p>
            <h6 class="mt-6 font-bold uppercase mb-5 text-indigo-600">No need for polling software , WhatsApp, social/messenger platforms, or Excel.</h6>
             <p class="text-slate-400 mx-auto">While our team is working hard to bring this platform live, we would love to hear from you about which features are most important for your team. If you have any ideas on how we can improve the cricket-life balance for team leaders and managers, please share your thoughts with us.</p>
        </div>
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div class="lg:col-span-5 md:col-span-6">
                <img src="assets/images/contact.svg" alt="">
            </div>

            <div class="lg:col-span-7 md:col-span-6">
                <div class="lg:ms-5">
                    <div class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                        <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                            <div class="grid lg:grid-cols-12 lg:gap-6">
                                <div class="lg:col-span-6 mb-5">
                                    <div class="text-start">
                                        <label for="name" class="font-semibold">Your Name: <span class="text-red-600">*</span></label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="user" class="size-4 absolute top-3 start-4"></i>
                                            <input id="name" formControlName="name" required type="text" class="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Name :">
                                            <div *ngIf="myForm.get('name')?.invalid && myForm.get('name')?.touched" class="error-message">
                                                Name is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lg:col-span-6 mb-5">
                                    <div class="text-start">
                                        <label for="email" class="font-semibold">Your Email:<span class="text-red-600">*</span></label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="mail" class="size-4 absolute top-3 start-4"></i>
                                            <input id="email" type="email" formControlName="email" required class="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Email :">
                                            <div *ngIf="myForm.get('email')?.invalid && myForm.get('email')?.touched" class="error-message">
                                                Email is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid lg:grid-cols-12 lg:gap-6">
                                <div class="lg:col-span-6 mb-5">
                                    <div class="text-start">
                                        <label for="subject" class="font-semibold">You are a :</label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="user" class="size-4 absolute top-5 start-4"></i>
                                            <select formControlName="playerType" class="form-select form-input ps-11 mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0">
                                                <option>Select One</option>
                                                <option>Everything</option>
                                                <option>Manager</option>
                                                <option>Captain / Vice Captain</option>
                                                <option>Player</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="lg:col-span-6 mb-5">
                                    <div class="text-start">
                                        <label for="featureyoulinke" class="font-semibold">Feature you like:</label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="check-circle" class="size-4 absolute top-5 start-4"></i>
                                            <select formControlName="feature" class="form-select form-input ps-11 mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0">
                                                <option>Select One</option>
                                                <option>All</option>
                                                <option>Automated Reminders</option>
                                                <option>Simplified Player Onboarding</option>
                                                <option>Team and Player Communication</option>
                                                <option>Manage Finances</option>
                                                <option>Personalized Dashboards</option>
                                                <option>Track your cricketing profile at one place.</option>
                                                <option>Find near-by cricket clubs</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid lg:grid-cols-12 lg:gap-6">
                                <div class="lg:col-span-6 mb-5">
                                    <div class="text-start">
                                        <label for="league" class="font-semibold">League:</label>
                                        <div class="form-icon relative mt-4">
                                            <i data-feather="users" class="size-4 absolute top-3 start-4"></i>
                                            <input formControlName="league" type="text" class="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Mid Atlantic Cricket Conference">
                                        </div>
                                    </div>
                                </div>
                                <div class="lg:col-span-6 mb-5">
                                    <div class="text-start">
                                        <label for="state" class="font-semibold">State:<span class="text-red-600">*</span></label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="check-circle" class="size-4 absolute top-5 start-4"></i>
                                            <select formControlName="state" class="form-select form-input ps-11 mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0">
                                                <option *ngFor="let state of states" [value]="state">{{ state }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid lg:grid-cols-12 lg:gap-6">
                                <div class="lg:col-span-12 mb-5">
                                    <div class="text-start">
                                        <label for="comments" class="font-semibold">Comment:</label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="message-circle" class="size-4 absolute top-3 start-4"></i>
                                            <textarea formControlName="customMessage" name="comments" id="comments" class="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Message :"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="errorMessage" class="p-5 border-t border-gray-100 dark:border-slate-800">
                                <div class="space-y-3">
                                    <div class="relative px-4 py-2 rounded-md font-medium bg-red-600/10 border border-red-600/10 text-red-600 block">{{errorMessage}}</div>
                                </div>
                            </div>
                           <button type="submit" id="submit" class="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<cricteams-app-footer-one/>
<div class="fixed top-0 left-0 bottom-0 right-0 z-999 bg-slate-800/25" [ngClass]="isActive === true ? '' : 'hidden' ">
    <div class="size-full flex justify-center items-center">
        <div class="rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 text-slate-900 dark:text-white inline-block">
            <div class="relative h-auto min-w-[480px]">
                <div class="flex justify-end items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                    <form method="dialog">
                        <button class="size-6 flex justify-center items-center shadow dark:shadow-gray-800 rounded-md btn-ghost" (click)="closeModal()"><i data-feather="x" class="size-4" ></i></button>
                    </form>
                </div>
                <div class="p-6 text-center">
                    <div class="size-24 bg-indigo-600/5 text-indigo-600 rounded-full text-5xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                        <i class="uil uil-thumbs-up"></i>
                    </div>
                    <h1 class="mt-6 mb-8 md:text-5xl text-3xl font-bold">Thank You</h1>
                    <p class="text-slate-400 my-3">We will notify you via email when the ship is ready to sail. <br> Please share it with other teams in your league</p>

                </div>
                <div *ngIf="canShare" class="flex justify-center items-center pb-6">
                    <button (click)="share()" id="share" class="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center">
                        <i data-feather="share-2" class="size-4 me-2"></i> Share
                    </button>
                </div>
                <div *ngIf="displayShareNotSupported" class="p-5 border-t border-gray-100 dark:border-slate-800">
                    <div class="space-y-3">
                        <div class="relative px-4 py-2 rounded-md font-medium bg-indigo-600/10 border border-indigo-600/10 text-indigo-600 block">Share not supported</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Add the loading spinner -->
<div *ngIf="isLoading">
    <cricteams-loading-spinner></cricteams-loading-spinner>
</div>

