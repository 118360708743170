<div class="page-wrapper" [ngClass]="{ 'toggled': activeSidebar }">

  <cricteams-sidebar/>

  <main class="page-content bg-gray-50 dark:bg-slate-800">

      <cricteams-header (toggleClass)="toggleClass()"/>

      <div class="container-fluid relative px-3">
          <div class="layout-specing">
            <div class="grid gap-6 font-montserrat" [ngClass]="{ 'grid-cols-3': !showDetailPage, 'xl:grid-cols-3': showDetailPage }">
              <div [ngClass]="{ 'col-span-3': !showDetailPage, 'col-span-2': showDetailPage }">
                <router-outlet></router-outlet>
              </div>
              <div class="col-span-2 xl:col-span-1" *ngIf="showDetailPage">
                  <cricteams-detail-view></cricteams-detail-view>
              </div>
            </div>
          </div>
      </div>
     <cricteams-app-footer/>

  </main>

</div>
