import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeagueDetailsComponent } from '../../pages/leagues/league-details/league-details.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { routeUrls } from '../../constants/constants';
import { TournamentsDetailsComponent } from '../../pages/tournaments/tournaments-details/tournaments-details.component';

@Component({
  selector: 'cricteams-detail-view',
  standalone: true,
  imports: [CommonModule, LeagueDetailsComponent, TournamentsDetailsComponent],
  templateUrl: './detail-view.component.html',
  styleUrl: './detail-view.component.scss',
})
export class DetailViewComponent implements OnInit {
  constructor(private router: Router) {}

  protected constants = routeUrls;
  protected activatedRoute = '';

  ngOnInit(): void {
    this.setActive();
    this.activatedRoute = this.router.url;
  }

  setActive(){
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.activatedRoute = this.router.url;
    });
  }
}
