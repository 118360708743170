@switch (activatedRoute) {
    @case ('/' + constants.LEAGUES) {
        <cricteams-league-details></cricteams-league-details>
    }
    @case ('/' + constants.TOURNAMENTS) {
      <cricteams-tournaments-details></cricteams-tournaments-details>
    }
    @case ('/' + constants.ADD_TOURNAMENTS){
      <cricteams-tournaments-details></cricteams-tournaments-details>
    }
    @case ('/' + constants.LIST_TOURNAMENTS) {
      <cricteams-tournaments-details></cricteams-tournaments-details>
    }
    @default {
        <p>Detail View</p>
        <p>{{ activatedRoute }}</p>
    }
}
